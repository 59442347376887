import dayjs from "dayjs";
var locale = {
    name: "mfe",
    weekStart: 1,
    months: "Zanvie_Fevirie_Mars_Avril_Mei_Zwin_Zilye_Out_Septam_Oktob_Novam_Desam".split("_"),
    monthsShort: "Zan_Fev_Mar_Avr_Mei_Zwi_Zil_Out_Sep_Okt_Nov_Des".split("_"),
    weekdays: "Dimans_Lindi_Mardi_Merkredi_Zedi_Vandredi_Samdi".split("_"),
    weekdaysShort: "Dim_Lin_Mar_Mer_Zed_Van_Sam".split("_"),
    weekdaysMin: "Di_Li_Ma_Me_Ze_Va_Sa".split("_"),
    formats: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D MMMM YYYY",
        LLL: "D MMMM YYYY HH:mm",
        LLLL: "dddd, D MMMM YYYY HH:mm"
    },
    relativeTime: {
        future: "dan %s",
        past: "%s desela",
        s: "enn ti segond",
        m: "1 minit",
        mm: "%d minit",
        h: "1 ler",
        hh: "%d ler",
        d: "1 zour",
        dd: "%d zour",
        M: "1 mwa",
        MM: "%d mwa",
        y: "1 an",
        yy: "%d an"
    },
    ordinal: function ordinal(n) {
        return "".concat(n, "e");
    }
};
dayjs.locale(locale, null, true);
export default locale;
