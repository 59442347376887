import dayjs from "dayjs";
var locale = {
    name: "mg",
    weekStart: 1,
    months: "Janoary_Febroary_Martsa_Aprily_Mey_Jona_Jolay_Aogositra_Septambra_Oktobra_Novambra_Desambra".split("_"),
    monthsShort: "Jan_Feb_Mar_Apr_Mey_Jon_Jol_Aog_Sep_Okt_Nov_Des".split("_"),
    weekdays: "Alahady_Alatsinainy_Talata_Alarobia_Kamis_Zoma_Asabotsy".split("_"),
    weekdaysShort: "Alah_Alat_Tal_Alar_Kam_Zom_Asa".split("_"),
    weekdaysMin: "Al_At_Ta_Al_Ka_Zo_As".split("_"),
    formats: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D MMMM YYYY",
        LLL: "D MMMM YYYY HH:mm",
        LLLL: "dddd, D MMMM YYYY HH:mm"
    },
    relativeTime: {
        future: "ao anatin'ny %s",
        past: "%s lasa",
        s: "segondra vitsy",
        m: "1 minitra",
        mm: "%d minitra",
        h: "1 ora",
        hh: "%d ora",
        d: "1 andro",
        dd: "%d andro",
        M: "1 volana",
        MM: "%d volana",
        y: "1 taona",
        yy: "%d taona"
    },
    ordinal: function ordinal(n) {
        return "".concat(n, "\xB0");
    }
};
dayjs.locale(locale, null, true);
export default locale;
